
<template>
  <div>
    <form @submit.prevent="submit">
      <div class="caption" v-if="status">Status: {{ status }}</div>
      <div v-if="serverError">Oops Error!{{ serverError }}</div>

      <v-text-field
        class="mx-4"
        v-model="address"
        required
        placeholder="cosmos-address"
        name="address"
        type="text"
      />
      <button
        :disabled="status === 'Registering...'"
        type="submit"
        class="button"
      ></button>

      <v-row class="justify-center mb-4">
        <vue-recaptcha
          v-show="status == '' || status == 'Registering...'"
          ref="recaptcha"
          @verify="onCaptchaVerified"
          @expired="onCaptchaExpired"
          @error="onCaptchaError"
          @render="onCaptchaRender"
          :sitekey="google"
        >
        </vue-recaptcha
      ></v-row>
      <v-alert type="success" v-if="sucessfulServerResponse">{{
        sucessfulServerResponse
      }}</v-alert>
    </form>
    <!---<v-btn color="primary" block @click="submit()">Submit</v-btn> -->

    <v-divider class="ma-4 mt-6" />
  </div>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import axios from "axios";
import ConfirmSignIn from "./ConfirmSignIn.vue";
export default {
  data() {
    return {
      status: "",
      address: "",
      sucessfulServerResponse: "",
      serverError: "",
      google: "6LdzO1waAAAAAKDkD1sNFSx552KIrXIr1F_NY_4O",
    };
  },
  methods: {
    submit: function () {
      // console.log(this.$refs.recaptcha.execute())
      this.status = "Registering...";
      // this.$refs.recaptcha.reset()
      this.$refs.recaptcha.execute();
    },
    onCaptchaRender: function (id) {
      //console.log({id})
    },
    onCaptchaError: function (error) {
      console.log({ error });
    },
    onCaptchaVerified: async function (recaptchaToken) {
      let accountQuery = await axios.get(
        "https://watchapi.trustlesshub.com/auth/accounts/" + this.address
      );

      console.log(accountQuery.data.result.value.address);
      if (!accountQuery.data.result.value.address) {
        //console.log("letsgo")

        this.status = "Submitting...";
        this.$refs.recaptcha.reset();
        try {
          this.status = "Getting TRST tokens";
          let response = await axios.post("/.netlify/functions/faucet", {
            recipient: this.address,
            recaptchaToken: recaptchaToken,
          });
          if (response.status === 200) {
            this.sucessfulServerResponse =
              "Your cosmos-address is succesfully registered!";
            alert("Sign up successfull");
            window.location.reload();
          } else {
            this.sucessfulServerResponse = response.data;
          }
        } catch (err) {
          console.log("ERROR" + err);
          alert("Error receiving tokens");
          window.location.reload();
          //let foo = getErrorMessage(err)
          //this.serverError = foo === '"read ECONNRESET"' ? 'Opps, we had a connection issue, please try again' : foo
        }
        this.status = "";
      } else {
        alert(
          "Account seems to already exist on the TRST blockchain, try signing in, or create a new account"
        );
      }
    },

    onCaptchaExpired: function () {
      this.status = "";
      this.$refs.recaptcha.reset();
    },
    getErrorMessage(err) {
      let responseBody;
      responseBody = err.response;
      if (!responseBody) {
        responseBody = err;
      } else {
        responseBody = err.response.data || responseBody;
      }
      return responseBody.message || JSON.stringify(responseBody);
    },
  },

  components: {
    VueRecaptcha,
    ConfirmSignIn,
  },
};
</script>